<template>
  <div class="approveds-list">
    <div class="approveds-list-box">
        <div class="approveds-list-box-header">
            <h2>רשימת מאשרים ולא מאשרים</h2>
            <div @click="$emit('close')" class="exit-btn">
                <i class="material-icons">close</i>
            </div>
        </div>
        <div class="approveds-list-box-content">
            <h2 style="text-align:center;">אישרו</h2>
            <h3 style="background:var(--blue); text-align:center; color:#fff;" v-if="event.approved.length == 0">אין עדיין נתונים</h3>
            <table v-else>
                <tr>
                    <th>שם</th>
                    <th>סניף</th>
                </tr>
                <template v-for="({name,user_uid,branche}) in event.approved" :key="user_uid">
                    <tr>
                        <td>{{name || 'לא צויין'}}</td>
                        <td>{{branche || 'לא צויין'}}</td>
                    </tr>
                </template>
            </table>
            <br>
            <hr>
            <h2 style="text-align:center;">לא אישרו</h2>
            <table>
                <tr>
                    <th style="background:var(--danger)">סניף</th>
                    <th style="background:var(--danger)">מס סניף</th>
                </tr>
                <template v-for="({name,user_uid,branche_num}) in who_not_marked" :key="user_uid">
                    <tr>
                        <td>{{name || 'לא צויין'}}</td>
                        <td>{{branche_num || 'לא צויין'}}</td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
    props:['event'],
    emits:['close'],
    setup(props){
        
        const who_not_marked = ref([])

        const handle_who_not_marked = () => {
            const set = new Set()
            for(const who of props.event.approved){
                set.add(who.branche_num)
            }
            
            who_not_marked.value = props.event.authorized.filter(({marked,branche_num}) => marked && !set.has(branche_num))
        }

        const init = () => {
            handle_who_not_marked()
        }

        init()
        return{
            who_not_marked
        }
    }
}
</script>

<style scoped>
    .approveds-list{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        padding: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
    }
    .approveds-list-box{
        width: 100%;
        max-width: 500px;
        height: 400px;
        background: #fff;
        border-radius: 10px;
    }
    .approveds-list-box-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid lightgray;
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--blue);
        border-radius: 50%;
        cursor: pointer;
        user-select: none;
    }

    .approveds-list-box-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;
        width: 50%;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--blue);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }

</style>